import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Endpoints } from "../../constants/Endpoints";
import { UserDetails } from "../../model/UserDetails";

export interface UserLoginAction extends Action {
    type: "@@user/login";
    payload: {
        user: UserDetails;
    }
}

export const loginAction = (email: string, password: string): ThunkAction<Promise<void>, {}, {}, UserLoginAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, UserLoginAction>): Promise<void> => {
        
        const response = await fetch(`${Endpoints.baseUrl}/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        });

        if (!response.ok) {
            alert('Failed to authenticate user.');
            return;
        }

        const userDetails = await response.json() as UserDetails;

        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        
        dispatch({
            type: "@@user/login",
            payload: {
                user: userDetails
            }
        });
    }
}

export interface UserLogoutAction extends Action {
    type: "@@user/logout";
    payload: {}
}

export const logoutAction = (): ThunkAction<Promise<void>, {}, {}, UserLogoutAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, UserLogoutAction>): Promise<void> => {
        localStorage.removeItem('userDetails');
        
        dispatch({
            type: "@@user/logout",
            payload: {}
        });
    }
}


export type UserAction = UserLoginAction | UserLogoutAction;