import { Reducer } from "react";
import { ProductDetails } from "../../model/Product";
import { ProductDetailsAction } from "../actions/product-details";

export interface ProductDetailsState {
    products: ProductDetails[];
}

const initialState: ProductDetailsState = {
    products: []
}

const ProductDetailsReducer: Reducer<ProductDetailsState, ProductDetailsAction> = (state: ProductDetailsState = initialState, action: ProductDetailsAction) => {
    switch (action.type) {
        case '@@product-details/get-all-product-details-action':
            return { ...state, products: action.payload.products };

        case '@@product-details/change-product-active-status':
            return {...state, products: [...state.products.filter(it => it.id !== action.payload.product.id), action.payload.product]}

        default:
            return state;
    }
}

export default ProductDetailsReducer;

