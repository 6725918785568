import React from 'react'

interface PlaiuriTextareaProps {
    rows: number;
    value: string;
    placeholder: string;
    readonly?: boolean;
    onChange: (val: string) => void;
}

function PlaiuriTextarea(props: PlaiuriTextareaProps) {

    return (
        < div className="mb-6 md:mb-0 my-3 mx-2" >
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Descriere producator</label>
            <textarea className={`appearance-none block w-full bg-gray-100 ${props.readonly ? 'text-gray-400' : 'text-gray-700 focus:bg-white' } focus:outline-none border border-red-500 rounded py-3 px-4 leading-tight overflow-scroll scrollbar-hide`}
                id="grid-first-name"
                rows={props.rows}
                readOnly={props.readonly}
                placeholder={props.placeholder}
                value={props.value} onChange={(it) => props.onChange(it.target.value)} />
        </div >
    )
}

export default PlaiuriTextarea