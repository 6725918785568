import { Table } from 'flowbite-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { ProducerDetails } from '../model/ProducerDetails';
import { getAllProducerDetailsAction, getAllProducerDetailsPendingReviewAction, reviewProducerDetailsAction } from '../store/actions/producer-details';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ReviewProducerDetailsPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { producerId } = useParams();

    const before: ProducerDetails = useSelector<ApplicationState, ProducerDetails>(state => state.producerDetails.producers
        .filter(it => it.reviewStatus === 'APPROVED')
        .filter(it => it.id === +producerId)[0]);

    const after: ProducerDetails = useSelector<ApplicationState, ProducerDetails>(state => state.producerDetails.pendingReview.filter(it => it.id === +producerId)[0]);


    useEffect(() => {
        dispatch(getAllProducerDetailsAction());
        dispatch(getAllProducerDetailsPendingReviewAction());
    }, [dispatch]);

    if (!after) {
        return <></>
    }

    const declineAndRevert = async () => {
        await dispatch(reviewProducerDetailsAction(+producerId, 'DECLINED'));
        navigate("/");

    }
    const approve = async () => {
        await dispatch(reviewProducerDetailsAction(+producerId, 'APPROVED'));
        navigate("/");
    }

    return (
        <div className='body-font font-source-sans-pro'>
            <Header />
            <div className='max-w-[1800px] mx-auto '>
                <div className='py-4 flex-1 pr-2'>
                    <div className="mx-4">
                        <Table striped={true}>
                            <Table.Head>
                                <Table.HeadCell>
                                    Field
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Before
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    After
                                </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Id</Table.Cell>
                                    <Table.Cell>{before?.id}</Table.Cell>
                                    <Table.Cell>{after.id}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Name</Table.Cell>
                                    <Table.Cell>{before?.name}</Table.Cell>
                                    <Table.Cell>{after.name}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Marketing name</Table.Cell>
                                    <Table.Cell>{before?.marketingName}</Table.Cell>
                                    <Table.Cell>{after.marketingName}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Version</Table.Cell>
                                    <Table.Cell>{before?.version}</Table.Cell>
                                    <Table.Cell>{after.version}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Version author</Table.Cell>
                                    <Table.Cell>{before?.versionAuthor}</Table.Cell>
                                    <Table.Cell>{after.versionAuthor}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Review status</Table.Cell>
                                    <Table.Cell>{before?.reviewStatus}</Table.Cell>
                                    <Table.Cell>{after.reviewStatus}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Producer type</Table.Cell>
                                    <Table.Cell>{before?.producerType}</Table.Cell>
                                    <Table.Cell>{after.producerType}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Description</Table.Cell>
                                    <Table.Cell>{before?.description}</Table.Cell>
                                    <Table.Cell>{after.description}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Region</Table.Cell>
                                    <Table.Cell>{before?.region}</Table.Cell>
                                    <Table.Cell>{after.region}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Image</Table.Cell>
                                    <Table.Cell>
                                        {
                                            before?.imgUrl
                                                ? <div style={{ backgroundImage: `url(${before?.imgUrl})`, width: 600, height: 400 }} className={`bg-cover bg-center relative mb-2`}></div>
                                                : ''
                                        }
                                    </Table.Cell>

                                    <Table.Cell>
                                        {
                                            after.imgUrl
                                                ? <div style={{ backgroundImage: `url(${after.imgUrl})`, width: 600, height: 400 }} className={`bg-cover bg-center relative mb-2`}></div>
                                                : ''
                                        }
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Administrator(s)</Table.Cell>
                                    <Table.Cell>{before?.administrators.map(it => it.email).join(',')}</Table.Cell>
                                    <Table.Cell>{after.administrators.map(it => it.email).join(',')}</Table.Cell>
                                </Table.Row>

                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Action</Table.Cell>
                                    <Table.Cell>
                                        {
                                            before && <div className='font-bold text-white cursor-pointer bg-red max-w-fit px-4 py-2 rounded-md' onClick={declineAndRevert}>
                                                Decline and revert to version {before.version}.
                                            </div>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div className='font-bold text-white cursor-pointer bg-primary max-w-fit px-4 py-2 rounded-md' onClick={approve}>
                                            Approve version {after.version}.
                                        </div>
                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewProducerDetailsPage