import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { PlaiuriDropdownSelect } from '../components/PlaiuriDropdownSelect';
import PlaiuriTextarea from '../components/PlaiuriTextarea';
import { PlaiuriTextInput } from '../components/PlaiuriTextInput';
import { COLORS } from '../constants/Colors';
import { ProducerDetails, ProducerType } from '../model/ProducerDetails';
import { getAllProducerDetailsAction, updateProducerAction } from '../store/actions/producer-details';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ProducerDetailsPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { producerId } = useParams();

    const producer: ProducerDetails = useSelector<ApplicationState, ProducerDetails>(state => state.producerDetails.producers.filter(it => it.id === +producerId)[0]);

    const [id, setId] = useState<number>(0);
    const [active, setActive] = useState<boolean>(false);
    const [administrators, setAdministrators] = useState<string>('');
    const [reviewStatus, setReviewStatus] = useState<string>('');
    const [version, setVersion] = useState<number>(0);
    const [versionAuthor, setVersionAuthor] = useState<string>('');
    const [producerName, setProducerName] = useState<string>('');
    const [marketingName, setMarketingName] = useState<string>('');
    const [producerType, setProducerType] = useState<ProducerType>(ProducerType.PFA);
    const [producerRegion, setProducerRegion] = useState<string>('');
    const [producerDescription, setProducerDescription] = useState<string>('');
    const [imgUrl, setImgUrl] = useState<string>('');

    useEffect(() => {
        if (!producer) {
            console.log("Re-fetching.")
            dispatch(getAllProducerDetailsAction())
        }

    }, [dispatch]);

    useEffect(() => {
        if (producer) {
            setId(producer.id);
            setActive(producer.active);
            setAdministrators(producer.administrators.map(it => it.email).join(","));
            setReviewStatus(producer.reviewStatus);
            setVersion(producer.version);
            setVersionAuthor(producer.versionAuthor);
            setProducerName(producer.name);
            setMarketingName(producer.marketingName);
            setProducerType(producer.producerType as ProducerType);
            setProducerRegion(producer.region);
            setProducerDescription(producer.description);
            setImgUrl(producer.imgUrl);
        }

    }, [producer]);

    if (!producer) {
        return <></>;
    }

    const stateChanged = false
        || active !== producer.active;

    const updateProducerDetails = async () => {
        await dispatch(updateProducerAction(+producerId, {
            name: producerName,
            marketingName: marketingName,
            producerType: producerType,
            description: producerDescription,
            region: producerRegion,
            imgUrl: imgUrl,
            administrators: producer.administrators,
            reviewStatus: reviewStatus,
            active: active 
        } as ProducerDetails))

        navigate("/");
    }

    return (
        <div className='body-font font-source-sans-pro'>
            <Header />
            <div className='max-w-[1800px] mx-auto '>
                <div className='flex flex-col w-full mt-4 bg-white rounded-md p-10 mx-4'>
                    <p className='text-3xl'>Producer details</p>

                    <div className='flex flex-row w-full'>
                        <div className='flex-1'>
                            <PlaiuriTextInput readonly label={'id'} value={id} type={'text'} onChange={(it) => setId(+it)} />
                            <PlaiuriDropdownSelect label={'Producer Activ'} options={['True', 'False']} value={active ? "True" : "False"} onValueChange={(it) => setActive(it === 'True')} />

                            <PlaiuriTextInput readonly label={'Status detalii'} value={reviewStatus} type={'text'} onChange={setReviewStatus} />
                            <PlaiuriTextInput readonly label={'Tip firma'} value={producerType} type={'text'} onChange={() => { }} />
                            <PlaiuriTextInput readonly label={'Regiune'} value={producerRegion} type={'text'} onChange={setProducerRegion} />
                            <PlaiuriTextInput readonly label={'Administrators'} value={administrators} type={'text'} onChange={setAdministrators} />

                            <div className='grid grid-cols-1 2xl:grid-cols-2'>
                                <PlaiuriTextInput readonly label={'Versiune'} value={version} type={'text'} onChange={(it) => setVersion(+it)} />
                                <PlaiuriTextInput readonly label={'Autor versiune'} value={versionAuthor} type={'text'} onChange={setVersionAuthor} />
                            </div>

                            <div className='grid grid-cols-1 2xl:grid-cols-2'>
                                <PlaiuriTextInput readonly label={'Nume oficial firma'} value={producerName} type={'text'} onChange={setProducerName} />
                                <PlaiuriTextInput readonly label={'Nume brandng'} value={marketingName} type={'text'} onChange={setMarketingName} />
                            </div>

                            <PlaiuriTextarea readonly rows={6} value={producerDescription} placeholder={"Descriere producator"} onChange={(it) => setProducerDescription(it)} />
                        </div>

                        <div className='flex flex-1 my-3 mx-2'>
                            <div className='flex flex-1 flex-col items-center justify-start'>
                                <div className='mb-4'>
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        <p>Imagine producator (600 x 400)</p>
                                    </label>

                                    {imgUrl && <div style={{ backgroundImage: `url(${imgUrl})`, width: 600, height: 400 }} className={`bg-cover bg-center relative mb-2`} ></div>}
                                    {!imgUrl && <div style={{ width: 600, height: 400 }} className={`bg-cover bg-center bg-gray-300 relative mb-2 flex items-center justify-center text-gray-400 font-bold`} >n/a</div>}
                                </div>
                            </div>
                        </div>

                    </div>

                    <button className="flex space-x-2 items-center px-8 py-2 bg-blue rounded-lg my-3 mx-2 w-fit" 
                            style={{ backgroundColor: stateChanged ? COLORS.primary : 'gray' }}
                            onClick={updateProducerDetails}>

                        <span className="text-white text-lg">Update</span>
                    </button>
                </div>
            </div>
        </div >
    )
}

export default ProducerDetailsPage