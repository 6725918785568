import { Table } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlaiuriDropdownSelectSm } from '../components/PlaiuriDropdownSelectSm';
import { ProductDetails } from '../model/Product';
import { changeProductActiveStatusAction, getAllProductDetailsAction } from '../store/actions/product-details';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ProductsPage() {

    const dispatch = useAppDispatch();

    const products: ProductDetails[] = useSelector<ApplicationState, ProductDetails[]>(state => state.productDetails.products);
  
    const [filteredProducts, setFilteredProducts] = useState<ProductDetails[]>([]);
    const [searchId, setSearchId] = useState<number>();
    const [searchTitle, setSearchTitle] = useState<string>();
    const [searchActive, setSearchActive] = useState<boolean>(null);
    const [searchDescription, setSearchDescription] = useState<string>();
    const [searchProducerId, setSearchProducerId] = useState<number>();
    const [searchProducerMarketingName, setSearchProducerMarketingName] = useState<string>();

    useEffect(() => {
        dispatch(getAllProductDetailsAction())
      }, [dispatch]);
  
      useEffect(() => {
          if (!products) {
              return;
          }
  
          const res = products.filter(it => !searchId || it.id === searchId)
                              .filter(it => !searchTitle || it.title?.toLocaleLowerCase().includes(searchTitle))
                              .filter(it => searchActive == null || it.active === searchActive)
                              .filter(it => !searchDescription || it.description?.toLocaleLowerCase().includes(searchDescription))
                              .filter(it => !searchProducerId || it.producer?.id === searchProducerId)
                              .filter(it => !searchProducerMarketingName || it.producer?.marketingName.toLocaleLowerCase().includes(searchProducerMarketingName));
  
          setFilteredProducts(res);
  
      }, [dispatch, products, searchId, searchTitle, searchActive ,searchDescription, searchProducerId, searchProducerMarketingName]);

    const changeProductActiveStatus = (productId: number, active: boolean) => {
        dispatch(changeProductActiveStatusAction(productId, active));
    }

    return (
        <div>
            <div className="mx-4">
                <Table striped={true}>
                    <Table.Head>
                        <Table.HeadCell>
                            ID
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Title
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Active
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Image
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Price Value
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Price Currency
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Quantity
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Quantity Unit
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Description
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Category
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Version
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Version Author
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Version Date
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Producer ID
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Producer marketing name
                        </Table.HeadCell>
                    </Table.Head>

                    <Table.Body className="divide-y">
                        <Table.Row key={0} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell>
                                <input type='text' 
                                       value={searchId}
                                       className='w-[40px] bg-gray-100 text-gray-400 appearance-none block border border-red-500 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white min-w-[50px]'
                                       onChange={it => setSearchId(+it.target.value)} />
                            </Table.Cell>

                            <Table.Cell>
                                <input type='text' 
                                       value={searchTitle}
                                       className='bg-gray-100 text-gray-400 appearance-none block w-full border border-red-500 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white min-w-[100px]'
                                       onChange={it => setSearchTitle(it.target.value)} />
                            </Table.Cell>
                            <Table.HeadCell>
                                <PlaiuriDropdownSelectSm 
                                    options={['', 'True', 'False']} 
                                    negativeValues={['False']}
                                    value={searchActive === true 
                                        ? 'True' 
                                        : searchActive === false ? 'False' : '' } 
                                    onValueChange={(it) => {
                                        switch (it) {
                                            case 'True':  
                                                setSearchActive(true);
                                                break;
                                            case 'False': 
                                                setSearchActive(false);
                                                break;
                                            default:      
                                                setSearchActive(null);
                                        }
                                }} />
                            </Table.HeadCell>
                            <Table.HeadCell>
                                {/* Image */}
                            </Table.HeadCell>
                            <Table.Cell>
                                {/* {it.priceValue} */}
                            </Table.Cell>
                            <Table.Cell>
                                {/* {it.priceCurrency} */}
                            </Table.Cell>
                            <Table.Cell>
                                {/* {it.quantity} */}
                            </Table.Cell>
                            <Table.Cell>
                                {/* {it.quantityUnit} */}
                            </Table.Cell>
                            <Table.Cell>
                                <input type='text' 
                                        value={searchDescription}
                                        className='bg-gray-100 text-gray-400 appearance-none block w-full border border-red-500 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white min-w-[100px]'
                                        onChange={it => setSearchDescription(it.target.value)} />
                            </Table.Cell>
                            <Table.Cell>
                                {/* {it.category.name} */}
                            </Table.Cell>
                            <Table.Cell>
                                {/* {it.version} */}
                            </Table.Cell>
                            <Table.Cell>
                                {/* {it.versionAuthor} */}
                            </Table.Cell>
                            <Table.Cell>
                                {/* {it.versionDate.toLocaleString('en-US')} */}
                            </Table.Cell>
                            <Table.Cell>
                                <input type='text' 
                                       value={searchProducerId}
                                       className='bg-gray-100 text-gray-400 appearance-none block w-full border border-red-500 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white min-w-[50px]'
                                       onChange={it => setSearchProducerId(+it.target.value)} />
                            </Table.Cell>
                            <Table.Cell>
                                <input type='text' 
                                       value={searchProducerMarketingName}
                                       className='bg-gray-100 text-gray-400 appearance-none block w-full border border-red-500 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white min-w-[100px]'
                                       onChange={it => setSearchProducerMarketingName(it.target.value)} />
                            </Table.Cell>
                        </Table.Row>
                        {
                            filteredProducts.sort((it1, it2) => it2.id - it1.id)
                                .map(it => (
                                    <Table.Row key={it.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell>
                                            {it.id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.title}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <PlaiuriDropdownSelectSm options={['True', 'False']} value={it.active ? 'True' : 'False'} onValueChange={(a) => changeProductActiveStatus(it.id, a === 'True')} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link to={it.imgUrl} >Image link</Link>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.priceValue}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.priceCurrency}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.quantity}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.quantityUnit}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.description}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.category.name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.version}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.versionAuthor}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.versionDate?.toLocaleString().replace('T', ' ').split('.')[0]}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.producer.id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {it.producer.marketingName}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default ProductsPage