import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from "redux";
import { Endpoints } from '../../constants/Endpoints';
import { ProducerDetails } from '../../model/ProducerDetails';
import { ApplicationState } from '../reducers';

export interface GetAllProducerDetailsAction extends Action {
    type: "@@producer-details/get-all-producer-details-action";
    payload: {
        producers: ProducerDetails[];
    }
}

export const getAllProducerDetailsAction = (): ThunkAction<Promise<void>, {}, {}, GetAllProducerDetailsAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, GetAllProducerDetailsAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/admin/producer-details`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            alert('Failed to fetch all producer details.');
            return;
        }

        const allProducersDetails = await response.json() as ProducerDetails[];

        dispatch({
            type: "@@producer-details/get-all-producer-details-action",
            payload: {
                producers: allProducersDetails
            }
        });
    }
}

export interface RegisterProducerAction extends Action {
    type: "@@producer-details/register-producer-action";
    payload: {
        producer: ProducerDetails;
    }
}

export const registerProducerAction = (producerDetails: ProducerDetails): ThunkAction<Promise<void>, {}, {}, RegisterProducerAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, RegisterProducerAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/admin/producer-details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(producerDetails)
        });

        if (!response.ok) {
            alert('Failed to register producer.');
            return;
        }

        const persistedProducerDetails = await response.json() as ProducerDetails;

        dispatch({
            type: "@@producer-details/register-producer-action",
            payload: {
                producer: persistedProducerDetails
            }
        });
    }
}

export interface GetAllProducerDetailsPendingReviewAction extends Action {
    type: "@@producer-details/get-all-producer-details-pending-review-action";
    payload: {
        producers: ProducerDetails[];
    }
}

export const getAllProducerDetailsPendingReviewAction = (): ThunkAction<Promise<void>, {}, {}, GetAllProducerDetailsPendingReviewAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, GetAllProducerDetailsPendingReviewAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/admin/producer-details/pending-review`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            alert('Failed to fetch all producer details.');
            return;
        }

        const allProducersDetails = await response.json() as ProducerDetails[];

        dispatch({
            type: "@@producer-details/get-all-producer-details-pending-review-action",
            payload: {
                producers: allProducersDetails
            }
        });
    }
}

export interface ReviewProducerDetailsAction extends Action {
    type: "@@producer-details/review-producer-details-action";
    payload: {
        producerId: number;
        status: string;
        producer: ProducerDetails;
    }
}

export const reviewProducerDetailsAction = (producerId: number, status: string): ThunkAction<Promise<void>, {}, {}, ReviewProducerDetailsAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, ReviewProducerDetailsAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/admin/producer-details/pending-review/${producerId}/${status}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            alert('Failed to review producer details.');
            return;
        }

        const updatedProducerDetails = await response.json() as ProducerDetails;

        dispatch({
            type: "@@producer-details/review-producer-details-action",
            payload: {
                producerId: updatedProducerDetails.id,
                status: status,
                producer: updatedProducerDetails
            }
        });
    }
}

export interface UpdateProducerAction extends Action {
    type: "@@producer-details/update-producer-action";
    payload: {
        producer: ProducerDetails;
    }
}

export const updateProducerAction = (producerId: number, producerDetails: ProducerDetails): ThunkAction<Promise<void>, {}, {}, UpdateProducerAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateProducerAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/admin/producer-details/${producerId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(producerDetails)
        });

        if (!response.ok) {
            alert('Failed to update producer.');
            return;
        }

        const persistedProducerDetails = await response.json() as ProducerDetails;

        dispatch({
            type: "@@producer-details/update-producer-action",
            payload: {
                producer: persistedProducerDetails
            }
        });
    }
}

export type ProducerDetailsAction = GetAllProducerDetailsAction | RegisterProducerAction | GetAllProducerDetailsPendingReviewAction | ReviewProducerDetailsAction | UpdateProducerAction;