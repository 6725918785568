import { Sidebar } from 'flowbite-react';
import { useEffect } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { BsBoxes } from 'react-icons/bs';
import { SiCodereview } from 'react-icons/si';
import { useSelector } from 'react-redux';
import Header from '../components/Header';
import { ProducerDetails } from '../model/ProducerDetails';
import { updateAdminTabAction } from '../store/actions/admin-navigation';
import { getAllProducerDetailsPendingReviewAction } from '../store/actions/producer-details';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';
import OperationsAccountsPage from './OperationsAccountsPage';
import OperationsCreateAccountPage from './OperationsCreateAccountPage';
import ProducersAccountsPage from './ProducersAccountsPage';
import ProducersCreatePage from './ProducersCreatePage';
import ProducersReviewPage from './ProducersReviewPage';
import ProductsPage from './ProductsPage';

function AdminPage() {
    const dispatch = useAppDispatch();

    const selectedPage: number = useSelector<ApplicationState, number>(state => state.adminNavigation.tabIndex);
    const pendingReview: ProducerDetails[] = useSelector<ApplicationState, ProducerDetails[]>(state => state.producerDetails.pendingReview);

    useEffect(() => {
        dispatch(getAllProducerDetailsPendingReviewAction())
    }, [dispatch]);

    let selectedPageMap = new Map<number, JSX.Element>([
        [0, <ProducersAccountsPage />],
        [1, <ProducersCreatePage />],
        [2, <OperationsAccountsPage />],
        [3, <OperationsCreateAccountPage />],
        [4, <ProducersReviewPage />],
        [5, <ProductsPage />],
    ]);

    const setSelectedPage = async (pageIndex: number) => {
        await dispatch(updateAdminTabAction(pageIndex));
    }

    return (
        <div className='body-font font-source-sans-pro'>
            <Header />

            <div className='mx-auto '>

                <div className='flex flex-row items-start py-4'>

                    <Sidebar className='py-4 ml-4 w-56 sticky top-20 z-50'>
                        <Sidebar.Items>
                            <Sidebar.ItemGroup>
                                <Sidebar.Collapse icon={AiOutlineHome} label="Producers" open={true}>
                                    <Sidebar.Item active={selectedPage === 0} className="cursor-pointer" onClick={() => { setSelectedPage(0) }}>
                                        List
                                    </Sidebar.Item>
                                    <Sidebar.Item active={selectedPage === 1} className="cursor-pointer" onClick={() => { setSelectedPage(1) }}>
                                        Create
                                    </Sidebar.Item>
                                </Sidebar.Collapse>

                                <Sidebar.Item active={selectedPage === 5} className="cursor-pointer" icon={BsBoxes} onClick={() => { setSelectedPage(5) }}>
                                    Products
                                </Sidebar.Item>

                                {/* <Sidebar.Collapse icon={MdOutlineSupervisorAccount} label="Operations" open={true}>
                                    <Sidebar.Item active={selectedPage === 2} className="cursor-pointer" onClick={() => { setSelectedPage(2) }}>
                                        Accounts
                                    </Sidebar.Item>
                                    <Sidebar.Item active={selectedPage === 3} className="cursor-pointer" onClick={() => { setSelectedPage(3) }}>
                                        Create account
                                    </Sidebar.Item>
                                </Sidebar.Collapse> */}

                                <Sidebar.Item active={selectedPage === 4} className="cursor-pointer" icon={SiCodereview} onClick={() => { setSelectedPage(4) }}>
                                    <div className='flex justify-between'>
                                        Reviews
                                        { pendingReview && <div className={`bg-gray-200 rounded-full w-7 h-7 flex text-center align-middle justify-center text-md`}>{pendingReview.length}</div> }
                                    </div>
                                </Sidebar.Item>
                            </Sidebar.ItemGroup>
                        </Sidebar.Items>
                    </Sidebar>

                    <div className='py-4 flex-1 pr-2'>
                        {selectedPageMap.get(selectedPage)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPage