import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';
import { reduxStore } from './store/store';
import AuthGuard from './utils/ProtectedRoute';
import ReviewProducerDetailsPage from './pages/ReviewProducerDetailsPage';
import ProducerDetailsPage from './pages/ProducerDetailsPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthGuard>
        <AdminPage />
      </AuthGuard>),
    errorElement: <ErrorPage />
  },
  {
    path: '/review-producer-details/:producerId',
    element: <ReviewProducerDetailsPage />,
  },
  {
    path: '/producer-details/:producerId',
    element: <ProducerDetailsPage />,
  },
  {
    path: '/login',
    element: <LoginPage />
  },

]);

function App() {
  return (
    <Provider store={reduxStore}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </Provider>
  )
}

export default App;