import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";

export interface UpdateAdminTabAction extends Action {
    type: "@@admin-navigation/update-active-tab";
    payload: {
        tabIndex: number;
    }
}

export const updateAdminTabAction = (tabIndex: number): ThunkAction<Promise<void>, {}, {}, UpdateAdminTabAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, UpdateAdminTabAction>): Promise<void> => {

        dispatch({
            type: "@@admin-navigation/update-active-tab",
            payload: {
                tabIndex: tabIndex
            }
        });
    }
}

export type AdminNavigationAction = UpdateAdminTabAction;