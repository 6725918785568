import { Table } from 'flowbite-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProducerDetails } from '../model/ProducerDetails';
import { getAllProducerDetailsPendingReviewAction } from '../store/actions/producer-details';
import { ApplicationState } from '../store/reducers';
import { useAppDispatch } from '../store/store';

function ProducersReviewPage() {
  const dispatch = useAppDispatch();

  const producers: ProducerDetails[] = useSelector<ApplicationState, ProducerDetails[]>(state => state.producerDetails.pendingReview);

  useEffect(() => {
    dispatch(getAllProducerDetailsPendingReviewAction())
  }, [dispatch]);

  return (
    <div>
      <div className="mx-4">
        <Table striped={true}>
          <Table.Head>
            <Table.HeadCell>
              ID
            </Table.HeadCell>
            <Table.HeadCell>
              Name
            </Table.HeadCell>
            <Table.HeadCell>
              Marketing Name
            </Table.HeadCell>
            <Table.HeadCell>
              Producer Type
            </Table.HeadCell>
            <Table.HeadCell>
              Region
            </Table.HeadCell>
            <Table.HeadCell>
              Administrator
            </Table.HeadCell>
            <Table.HeadCell>
              Changes
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y">
            {
              producers?.sort((it1, it2) => it2.id - it1.id)
                .map(it => (
                  <Table.Row key={it.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell>
                      {it.id}
                    </Table.Cell>
                    <Table.Cell>
                      {it.name}
                    </Table.Cell>
                    <Table.Cell>
                      {it.marketingName}
                    </Table.Cell>
                    <Table.Cell>
                      {it.producerType !== 'PRODUCATOR_AUTORIZAT' ? it.producerType : "PA"}
                    </Table.Cell>
                    <Table.Cell>
                      {it.region}
                    </Table.Cell>
                    <Table.Cell>
                      {it.administrators[0]?.email}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/review-producer-details/${it.id}`}>
                        <div className='font-bold text-primary'>
                          Review changes
                        </div>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))
            }
          </Table.Body>
        </Table>
      </div>
    </div>

  )
}

export default ProducersReviewPage