import { Reducer } from "redux";
import { AdminNavigationAction } from './../actions/admin-navigation';

export interface AdminNavigationState {
    tabIndex: number;
}

const initialState: AdminNavigationState = {
    tabIndex: 0,
}

const AdminNavigationReducer: Reducer<AdminNavigationState, AdminNavigationAction> = (state: AdminNavigationState = initialState, action: AdminNavigationAction) => {

    switch (action.type) {
        case '@@admin-navigation/update-active-tab':
            return { ...state, tabIndex: action.payload.tabIndex }

        default:
            return state;
    }
}

export default AdminNavigationReducer;