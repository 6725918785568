import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { PlaiuriTextInput } from '../components/PlaiuriTextInput';
import { loginAction } from '../store/actions/auth';
import { useAppDispatch } from '../store/store';

function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [uiVersion, setUiVersion] = useState(0);

    const [error, setError] = useState<string | null>('');

    const loginHandler = async () => {
        setError(null);

        try {
            await dispatch(loginAction(email, password));
            navigate("/");

        } catch (err) {
            setEmail('');
            setPassword('');
            setError((err as Error).message);
            setUiVersion(uiVersion + 1);
        }
    };

    return (
        <div className='body-font font-source-sans-pro'>
            <Header />

            <div className='max-w-[1800px] mx-auto'>
                <div className='flex flex-col items-center justify-start h-screen'>

                    <div className='flex flex-col items-center justify-center bg-white rounded-md shadow-sm w-96 py-4 mt-10 md:mt-40'>
                        <PlaiuriTextInput key={`email-${uiVersion}`} label={'Email'} placeholder={'exemplu@ceva.ro'} value={email} type={'text'} onChange={setEmail} />
                        <PlaiuriTextInput key={`password-${uiVersion}`} label={'Password'} value={password} type={'password'} onChange={setPassword} />

                        <div className='flex flex-col w-full items-start px-8'>
                            <button className="flex space-x-2 items-center px-4 py-2 bg-blue rounded-lg my-2 " onClick={() => { loginHandler() }}>
                                <span className="text-white text-lg">Autentificare</span>
                            </button>

                            {
                                error && <div className='text-red bg-lightRed w-full py-4 rounded-md px-4 my-2'>
                                    <p className='font-bold'>Authentication error</p>
                                    <p>{error}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage