import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { Endpoints } from '../../constants/Endpoints';
import { ApplicationState } from '../reducers';
import { ProductDetails } from './../../model/Product';

export interface GetAllProductDetailsAction extends Action {
    type: "@@product-details/get-all-product-details-action";
    payload: {
        products: ProductDetails[];
    }
}

export const getAllProductDetailsAction = (): ThunkAction<Promise<void>, {}, {}, GetAllProductDetailsAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, GetAllProductDetailsAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/admin/product-details`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            alert('Failed to fetch all product details.');
            return;
        }

        const remoteProducts = await response.json() as ProductDetails[];

        dispatch({
            type: "@@product-details/get-all-product-details-action",
            payload: {
                products: remoteProducts
            }
        });
    }
}

export interface ChangeProductActiveStatusAction extends Action {
    type: "@@product-details/change-product-active-status";
    payload: {
        product: ProductDetails;
    }
}

export const changeProductActiveStatusAction = (productId: number, active: boolean): ThunkAction<Promise<void>, {}, {}, ChangeProductActiveStatusAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, ChangeProductActiveStatusAction>, getState): Promise<any> => {
        const state = getState() as ApplicationState;

        if (state.user.user === null) {
            alert("No authenticated user!");
            return;
        }

        const token = state.user.user?.token;

        const response = await fetch(`${Endpoints.baseUrl}/admin/product-details/${productId}/${active}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            alert('Failed to change product active status.');
            return;
        }

        const remoteProduct = await response.json() as ProductDetails;

        dispatch({
            type: "@@product-details/change-product-active-status",
            payload: {
                product: remoteProduct
            }
        });
    }
}

export type ProductDetailsAction = GetAllProductDetailsAction | ChangeProductActiveStatusAction;