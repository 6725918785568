

interface PlaiuriDropdownSelectProps {
    label: string;
    options: string[];
    value: string | null;
    required?: boolean;
    onValueChange: (val: string) => void;
}

export const PlaiuriDropdownSelect = (props: PlaiuriDropdownSelectProps) => {

    return (
        <div className="relative w-80 mb-6 md:mb-0 my-3 mx-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                {props.label}
            </label>

            <select className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                value={props.value}
                required={props.required}
                onChange={(e) => props.onValueChange(e.target.value)}
            >
                {
                    props.options.map(it => <option key={it}>{it}</option>)
                }
            </select>
        </div>
    );
}