import { Reducer } from "redux";
import { ProducerDetails } from './../../model/ProducerDetails';
import { ProducerDetailsAction } from './../actions/producer-details';

export interface ProducerDetailsState {
    producers: ProducerDetails[];
    pendingReview: ProducerDetails[];
}

const initialState: ProducerDetailsState = {
    producers: [],
    pendingReview: [],
}

const ProducerDetailsReducer: Reducer<ProducerDetailsState, ProducerDetailsAction> = (state: ProducerDetailsState = initialState, action: ProducerDetailsAction) => {

    switch (action.type) {
        case '@@producer-details/get-all-producer-details-action':
            return { ...state, producers: action.payload.producers };

        case '@@producer-details/register-producer-action':
            return { ...state, producers: [action.payload.producer, ...state.producers] };

        case '@@producer-details/get-all-producer-details-pending-review-action':
            return { ...state, pendingReview: [...action.payload.producers] };

        case '@@producer-details/review-producer-details-action': {
            const updatedProducers = [...state.producers.filter(it => it.id != action.payload.producerId), action.payload.producer];
            const updatedPendingReview = [...state.pendingReview.filter(it => it.id != action.payload.producerId)];

            return {
                ...state, 
                producers: updatedProducers,
                pendingReview: updatedPendingReview,
            };
        }

        case '@@producer-details/update-producer-action': {
            const updatedProducers = [...state.producers.filter(it => it.id != action.payload.producer.id), action.payload.producer];

            return {
                ...state, 
                producers: updatedProducers,
            };
        }

        default:
            return state;
    }
}

export default ProducerDetailsReducer;