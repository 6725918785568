import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { PlaiuriDropdownSelect } from '../components/PlaiuriDropdownSelect';
import PlaiuriTextarea from '../components/PlaiuriTextarea';
import { PlaiuriTextInput } from '../components/PlaiuriTextInput';
import { ProducerAdministrator } from '../model/ProducerAdministrator';
import { ProducerDetails, ProducerType } from '../model/ProducerDetails';
import { updateAdminTabAction } from '../store/actions/admin-navigation';
import { registerProducerAction } from '../store/actions/producer-details';
import { useAppDispatch } from '../store/store';

function ProducersCreatePage() {
  const dispatch = useAppDispatch();

  const schema = yup.object().shape({
    producerName: yup.string().required('NUME LEGAL FIRMA is a required field.'),
    marketingName: yup.string().required('NUME BRANDING is a required field.'),
    producerRegion: yup.string().required('REGIUNE is a required field.'),
    producerAdminEmail: yup.string().email('ADMINISTRATOR EMAIL is not in a valid format.').required('ADMINISTRATOR EMAIL is a required field.'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  const [producerName, setProducerName] = useState('');
  const [marketingName, setMarketingName] = useState('');
  const [producerType, setProducerType] = useState<ProducerType>(Object.values(ProducerType)[0]);
  const [producerRegion, setProducerRegion] = useState('');
  const [producerDescription, setProducerDescription] = useState('');
  const [producerAdminEmail, setProducerAdminEmail] = useState('');

  const saveProducerDetails = async () => {
    const administrator: ProducerAdministrator = {
      email: producerAdminEmail
    } as ProducerAdministrator;

    const producerUpdates: ProducerDetails = {
      id: -1,
      name: producerName,
      marketingName: marketingName,
      producerType: producerType,
      description: producerDescription,
      region: producerRegion,
      administrators: [administrator],

    } as ProducerDetails;

    await dispatch(registerProducerAction(producerUpdates));
    await dispatch(updateAdminTabAction(0));
  }

  return (
    <div className='flex flex-col bg-white rounded-md p-4 px-4 mx-2'>
      <p className='text-3xl'>Detalii producator</p>

      <div className='flex flex-row w-full'>
        <div className='flex-1'>
          <form onSubmit={handleSubmit(saveProducerDetails)}>
            <PlaiuriTextInput
              validationProps={{ ...register('producerAdminEmail') }}
              isInvalid={errors.producerAdminEmail != null}
              isInvalidMessage={errors.producerAdminEmail?.message.toString()}
              label={'Administrator email'}
              value={producerAdminEmail}
              type={'text'}
              onChange={setProducerAdminEmail} />

            <PlaiuriTextInput
              validationProps={{ ...register('producerName') }}
              isInvalid={errors.producerName != null}
              isInvalidMessage={errors.producerName?.message.toString()}
              label={'Nume legal firma'}
              value={producerName}
              type={'text'}
              onChange={setProducerName} />

            <PlaiuriTextInput
              validationProps={{ ...register('marketingName') }}
              isInvalid={errors.marketingName != null}
              isInvalidMessage={errors.marketingName?.message.toString()}
              label={'Nume brandng'}
              value={marketingName}
              type={'text'}
              onChange={setMarketingName} />

            <PlaiuriDropdownSelect label={'Tip firma'} options={Object.values(ProducerType)} value={producerType} onValueChange={(it) => setProducerType(it as ProducerType)} />

            <PlaiuriTextInput
              validationProps={{ ...register('producerRegion') }}
              isInvalid={errors.producerRegion != null}
              isInvalidMessage={errors.producerRegion?.message.toString()}
              label={'Regiune'}
              value={producerRegion}
              type={'text'}
              onChange={setProducerRegion} />

            <div className='w-1/2'>
              <PlaiuriTextarea rows={6} value={producerDescription} placeholder={"Descriere producator"} onChange={(it) => setProducerDescription(it)} />
            </div>

            <button className="flex space-x-2 items-center px-4 py-2 bg-blue rounded-lg my-3 mx-2">

              <span className="text-white text-lg">Salveaza</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ProducersCreatePage