import { ProducerAdministrator } from './ProducerAdministrator';

export interface ProducerDetails {
    id: number;
    name: string;
    marketingName: string;
    producerType: string;
    description: string;
    region: string;
    imgUrl: string;
    administrators: ProducerAdministrator[];
    reviewStatus: string;
    version: number;
    versionAuthor: string;
    active: boolean;
};

export enum ProducerType {
    SRL = "SRL",
    PFA = "PFA",
    PRODUCATOR_AUTORIZAT = "PRODUCATOR_AUTORIZAT"
}