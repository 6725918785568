
interface PlaiuriDropdownSelectProps {
    options: string[];
    value: string | null;
    negativeValues?: string[];
    required?: boolean;
    onValueChange: (val: string) => void;
}

export const PlaiuriDropdownSelectSm = (props: PlaiuriDropdownSelectProps) => {

    return (
        <div className="relative mx-2">

            <select className="px-2 py-1 text-gray-500 bg-white border rounded-md shadow-sm outline-none focus:border-primary"
                value={props.value}
                required={props.required}
                onChange={(e) => props.onValueChange(e.target.value)}
            >
                {
                    props.options.map(it => <option key={it}>{it}</option>)
                }
            </select>
        </div>
    );
}