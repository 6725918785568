import ProductDetailsReducer, { ProductDetailsState } from './reducers/product-details';
import { combineReducers, Reducer } from "redux";
import AdminNavigationReducer, { AdminNavigationState } from './reducers/admin-navigation';
import UserReducer, { UserState } from "./reducers/auth";
import ProducerDetailsReducer, { ProducerDetailsState } from './reducers/producer-details';

export interface ApplicationState {
    user: UserState;
    producerDetails: ProducerDetailsState;
    productDetails: ProductDetailsState;
    adminNavigation: AdminNavigationState;
}

export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    user: UserReducer,
    producerDetails: ProducerDetailsReducer,
    productDetails: ProductDetailsReducer,
    adminNavigation: AdminNavigationReducer,
});