import { Reducer } from "redux";
import { UserDetails } from "../../model/UserDetails";
import { UserAction } from './../actions/auth';

const getInitialUser = () => {
    const cachedUserString = localStorage.getItem('userDetails');
    if (!cachedUserString) {
        return null;
    }

    return JSON.parse(cachedUserString);
}

export interface UserState {
    user: UserDetails | undefined;
}

const initialState: UserState = {
    user: getInitialUser(),
}

const UserReducer: Reducer<UserState, UserAction> = (state: UserState = initialState, action: UserAction) => {
    
    switch (action.type) {
        case '@@user/login':
            return { ...state, user: action.payload.user };

        case '@@user/logout':
            return initialState;

        default:
            return state;
    }
}

export default UserReducer;